<template>
  <div>

    <table border="1" class="tableAB" v-if="type=='OTTOMAN-1000'">
      <thead class="theadAB">
        <tr>
          <th>p1(空杯)</th>
          <th>p2(加R1)</th>
          <th>p3(加样品搅拌)</th>
          <th>p4</th>
          <th>p5</th>
          <th>p6(加R2搅拌)</th>
          <th>p7</th>
          <th>p8</th>
          <th :colspan="last.colspan">{{last.text}}</th>
          
        </tr>
       
      </thead>
      <tbody class="tbodyAB">
        <tr>
          <td v-for="(item,index) in pList" :key="index"> {{item}}</td>
        </tr>
      </tbody>
    </table>

    <table border="1" class="tableAB" v-if="type=='ottoman2020_A'">
      <thead class="theadAB">
        <tr>
          <th>p1(水空白)</th>
          <th>p2(杯空白)</th>
          <th>p3(加R3)</th>
          <th>p4(加样本)</th>
          <th>p5(搅拌)</th>
          <th colspan='3'>
            p6-P8
          </th>
          <th>p9(加R1)</th>
          <th>p10(未搅拌)</th>
          <th>p11(搅拌)</th>
          <th colspan='5'>p12-p16</th>
          <th>p17(加R2)</th>
          <th>p18(搅拌)</th>
          <th :colspan="last.colspan">{{last.text}}</th>
        </tr>
      </thead>
      <tbody class="tbodyAB">
        <tr>
          <td v-for="(item,index) in pList" :key="index"> {{item}}</td>
        </tr>
      </tbody>
    </table>

    <table border="1" class="tableAB" v-if="type=='ottoman2020_O'">
      <thead class="theadAB">
        <tr>
          <th>p1(水空白吸光度)</th>
          <th>p2(杯空白吸光度)</th>
          <th>p3(加R1)</th>
          <th>p4(加样本)</th>
          <th>p5(搅拌)</th>
          <th colspan='5'>
            p6-P10
          </th>
          <th>p11(加R2搅拌)</th>
          <th :colspan="last.colspan">{{last.text}}</th>
        </tr>
       
      </thead>
      <tbody class="tbodyAB">
        <tr>
          <td v-for="(item,index) in pList" :key="index"> {{item}}</td>
       
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ['value','type'],
  data() {
    return {
      pList: [],
      last: {
        text: '',
        colspan: 1,
      },
      ottoman2020_O_last: {

      },
      ottoman2020_O_last: {

      },
    }
  },
  created() {
    // this.pList = this.value
    this.value.forEach(e=>{
      if(e!=null){
        this.pList.push(e)
      }
    })
    switch (this.type) {
      case 'OTTOMAN-1000':
        this.last.colspan = this.pList.length-8
        this.last.text = this.last.colspan > 1?`p9-p${this.pList.length}`:'p9'
        break;
      case 'ottoman2020_O':
        this.last.colspan = this.pList.length-11
        this.last.text = this.last.colspan > 1?`p12-p${this.pList.length}`:'P12'
        break;
      case 'ottoman2020_A':
        this.last.colspan = this.pList.length-18
        this.last.text = this.last.colspan > 1?`p19-p${this.pList.length}`:'P19'
        break;
      default:
        break;
    }
    
  },
}
</script>

<style lang="scss" scoped>
.tableAB{
  width:100%;
  border-spacing: 0;
  border-collapse: collapse;
  .theadAB{
    tr {
      th {
        font-size: px(20);
        text-align: center;
      }
    }
   
  }
  .tbodyAB {
    tr {
      td {
        font-size: px(20);
        text-align: center;
      }
    }
  }
}
</style>>
