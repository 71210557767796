<template>
  <div class="TJDetailMenu">
    <div
      :class="[
        {
          'rightBorder': item.rightBorder == false,
          'bottomBorder': item.bottomBorder == false,
          'absorbance': item.type != null
        },
        'box',
      ]"
      v-for="(item, index) of dataList"
      :key="index"
      :style="'width:' + item.width"
    >
      <p v-if="item.status">{{ item.name }}({{ item.status }}):</p>
      <p v-else>{{ item.name }}:
        <i v-if="item.field_name== 'unlock'" :class="[isVisible ? 'visible' : 'invisible', 'unlock']" @click="handleChangeVisible"></i>
      </p>
      <p v-if="item.type" class="absorbance">
        <!-- 吸光度 -->
        <absorbance :value="item.value" :type="item.type"></absorbance>
      </p>
      <p v-else>
        <span v-if="item.field_name== 'unlock'" class="unlock_code">{{isVisible ? unlock_code : item.value}}</span>
        <i v-if="item.field_name== 'unlock' && isVisible" @click="copyText(unlock_code)" class="copy_font"></i>
        <span v-if="item.field_name !== 'unlock'">{{ item.value == null ? '-' : item.value }}</span>
        <span v-if="item.nameCode =='instrument_id'" class="status_tag" :style="statusStyle(item.gprs_status).style">{{statusStyle(item.gprs_status).name}}</span>
      </p>
    </div>
  </div>
</template> 
<script>
import absorbance from './absorbance.vue'
import apiDetail from '@/api/searchDetail.js'
export default {
  props: ['dataList', 'lacCode'],
  components: {absorbance},
  data() {
    return {
      isVisible: false,
      unlock_code: '',
    }
  },
  methods: {
    handleChangeVisible(){
      this.isVisible = !this.isVisible
      if(this.isVisible){
        this.getUnlockCode()
      }
    },
    getUnlockCode() {
      let params = {
        instrument_id: this.$route.query.id,
        lac: this.lacCode
      }
      apiDetail.get_unlock_code(params).then((res) => {
        if (res.code == 200){
          this.$message.success('解锁码获取成功')
          this.unlock_code = res.data
        }
      }).finally(()=>{

      })
    },
    statusStyle(status) {
      let statusObj = {
        style:'',
        name: ''
      }
      let style = ''
      switch (status) {
        case 2001:
          statusObj.style = 'color:#81B337;border:1px solid #81B337;'
          statusObj.name = '正常'
          break;
        case 2002:
          statusObj.style = 'color:#E99D42;border:1px solid #E99D42;'
          statusObj.name = '停用'
          break;
        case 2003:
          statusObj.style = 'color:#A16222;border:1px solid #A16222;'
          statusObj.name = '备用机'
          break;
        case 2004:
          statusObj.style = 'color:#9A9A9A;border:1px solid #9A9A9A;'
          statusObj.name = '未启用'
          break;
        case 2005:
          statusObj.style = 'color:#BD3124;border:1px solid #BD3124;'
          statusObj.name = '传输失败'
          break;
        default:
          break;
      }
      return statusObj
    },
    copyText(value){
      //获取需要复制的文本内容。
      const text = value;
      // 创建一个临时的 textarea 元素。
      const el = document.createElement('textarea');
      // 将需要复制的文本内容赋值给 textarea 元素。
      el.value = text;
      //将 textarea 元素添加到页面中。
      document.body.appendChild(el);
      //选中 textarea 元素中的文本。
      el.select();
      // 执行复制命令，将选中的文本复制到剪贴板
      document.execCommand('copy');
      //从页面中移除 textarea 元素。
      document.body.removeChild(el);
      this.$message.success('复制成功')
    },
  }
}
</script>
<style lang="scss" scoped>
.TJDetailMenu {
  min-height: px(90);
  background-color: #fff;
  border-radius: 5px;
  padding: 0 px(8);
  display: flex;
  margin-bottom: px(20);
  font-size: px(20);
  flex-wrap: wrap;
  box-sizing: border-box;
  .box {
    width: 33%;
    border-right: 1px solid #ebf0f6;
    box-sizing: border-box;
    padding: px(10) 0 0 px(10);
    height: px(80);
    border-bottom: 1px solid #ebf0f6;
    p:nth-child(1) {
      color: #999999;
      margin-bottom: px(6);
    }
    p:nth-child(2) {
      height: px(19);
      color: #333333;
    }
  }
  .box:nth-last-child(1) {
    border-right: none;
  }

  .bottomBorder {
    border-bottom: none !important;
  }
  .rightBorder {
    border-right: none;
  }
  .absorbance {
    height: px(100);
  }
}
.status_tag{
  padding: 2px px(7);
  font-size: px(14);
  vertical-align: px(2);
  border-radius: 3px;
  margin-left: 6px;
}
.unlock_code{
  font-weight: bold;
}
.visible{
  display: inline-block;
  width: px(24);
  height: px(18);
  background: url("../assets/img/visible.png");
  background-size: cover;
}
.invisible{
  width: px(24);
  height: px(18);
  display: inline-block;
  background: url('../assets/img/invisible.png');
  background-size: cover;
}
.copy_font{
  width: px(16);
  height: px(16);
  vertical-align: 3px;
  display: inline-block;
  background: url('../assets/img/copy.png');
  background-size: cover;
  margin-left: 4px;
}
</style>